import gameclosure from 'assets/images/everwing/GC_logo.png';
import gcturbo from 'assets/images/pokemon/GCTurbo_logo.png';
import machineZone from 'assets/images/finalFantasy/machinezone_logo_dark.png';
import pokemon from 'assets/images/pokemon/Logo_PokemonMedallion.png';
import rakuten from 'assets/images/bustAMove/BMove_Rakuten_Logo.png';
import squareEnix from 'assets/images/finalFantasy/squareEnix_logo.png';
import storm8 from 'assets/images/hungryBabies/logo_storm8.png';
import taito from 'assets/images/bustAMove/BMove_Taito_Logo.png';

export const getLogoSrc = (company: string) => {
  switch (company) {
  case 'Gameclosure, Inc.':
    return gameclosure;
  case 'GC Turbo':
    return gcturbo;
  case 'Machine Zone':
    return machineZone;
  case 'Pokemon Medallion Battle':
    return pokemon;
  case 'Rakuten Games':
    return rakuten;
  case 'Square Enix':
    return squareEnix;
  case 'Storm 8 Inc.':
    return storm8;
  case 'Taito Games':
    return taito;
  default:
    return '';
  }
};