export type Project = {
  caption?: string;
  homepagePreview: boolean;
  id?: string;
  image: StaticImageData;
  link?: string;
  name: string;
}

export type ProjectPreviews = {
  professional: Project[];
  personal: Project[];
};

import adastra from 'assets/images/personal-art/sketch-adastra.png';
import bustAMoveBlitz from 'assets/images/Cover_BustAMoveBlitz_2.png';
import candyBlastMania from 'assets/images/Cover_CandyBlastMania.png';
import eagle from 'assets/images/personal-art/harpyeagle001.png';
import evertown from 'assets/images/Cover_Evertown.png';
import everwing from 'assets/images/Cover_Everwing.png';
import evilKingdomFinal from 'assets/images/personal-art/evilkingdomfinal.png';
import finalFantasy from 'assets/images/Cover_FinalFantasy.png';
import hummingbird from 'assets/images/personal-art/sketch-hummingbird.png';
import hungryBabiesMania from 'assets/images/Cover_HungryBabiesMania.png';
import legionOfHonor from 'assets/images/personal-art/sketch-legionofhonor1.png';
import monkey from 'assets/images/personal-art/300dpimonkey.png';
import monkeyMan from 'assets/images/personal-art/sketch-monkeyman.png';
import pie from 'assets/images/personal-art/acrylic-pie.png';
import pokemon from 'assets/images/Cover_Pokemon.png';
import portraitFemale from 'assets/images/personal-art/sketch-female-portrait.png';
import portraitMale from 'assets/images/personal-art/sketch-singleportrait.png';
import reindeer from 'assets/images/personal-art/sketch-reindeer.png';
import variousPortraits from 'assets/images/personal-art/sketch-various-portraits.png';
import vikingGirl from 'assets/images/personal-art/sketch-vikinggirl.png';
import waterfall from 'assets/images/personal-art/newidea2555.png';

const professional = [
  {
    caption: 'Final Fantasy: War for Eos',
    homepagePreview: true,
    image: finalFantasy,
    link: '/finalfantasy',
    name: 'Final Fantasy: War for Eos',
  },
  {
    caption: 'Pokémon Medallion Battle',
    homepagePreview: true,
    image: pokemon,
    link: '/pokemon',
    name: 'Pokémon Medallion Battle',
  },
  {
    caption: 'Everwing',
    homepagePreview: true,
    image: everwing,
    link: '/everwing',
    name: 'Everwing',
  },
  {
    caption: 'Bust A Move Blitz',
    homepagePreview: true,
    image: bustAMoveBlitz,
    link: '/bustamove',
    name: 'Bust A Move Blitz',
  },
  {
    caption: 'Evertown',
    homepagePreview: false,
    image: evertown,
    link: '/evertown',
    name: 'Evertown',
  },
  {
    caption: 'Candy Blast Mania',
    homepagePreview: false,
    image: candyBlastMania,
    link: '/candyblastmania',
    name: 'Candy Blast Mania',
  },
  {
    caption: 'Hungry Babies Mania',
    homepagePreview: false,
    image: hungryBabiesMania,
    link: '/hungrybabiesmania',
    name: 'Hungry Babies Mania',
  }
];

const personal = [
  {
    homepagePreview: true,
    id: '1',
    image: monkey,
    link: '/personal#1',
    name: 'Monkey',
  },
  {
    homepagePreview: false,
    id: '2',
    image: eagle,
    name: 'Harpy Eagle',
  },
  {
    homepagePreview: true,
    id: '3',
    image: pie,
    link: '/personal#3',
    name: 'Blueberry Pie',
  },
  {
    homepagePreview: false,
    id: '4',
    image: reindeer,
    name: 'Reindeer',
  },
  {
    homepagePreview: false,
    id: '5',
    image: variousPortraits,
    name: 'Various Portraits',
  },
  {
    homepagePreview: false,
    id: '6',
    image: portraitMale,
    name: 'Portrait Male',
  },
  {
    homepagePreview: false,
    id: '7',
    image: monkeyMan,
    name: 'Monkey Man',
  },
  {
    homepagePreview: false,
    id: '8',
    image: evilKingdomFinal,
    name: 'Evil Kingdom Final',
  },
  {
    homepagePreview: false,
    id: '9',
    image: portraitFemale,
    name: 'Portrait Female',
  },
  {
    homepagePreview: true,
    id: '10',
    image: waterfall,
    link: '/personal#10',
    name: 'Waterfall',
  },
  {
    homepagePreview: false,
    id: '11',
    image: adastra,
    name: 'Adastra',
  },
  {
    homepagePreview: false,
    id: '12',
    image: hummingbird,
    name: 'Hummingbird',
  },
  {
    homepagePreview: true,
    id: '13',
    image: vikingGirl,
    link: '/personal#13',
    name: 'Viking Girl',
  },
  {
    homepagePreview: false,
    id: '14',
    image: legionOfHonor,
    name: 'Legion Of Honor',
  },
];

export const projectPreviews: ProjectPreviews = {
  professional,
  personal,
};
