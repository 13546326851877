import styled from 'styled-components';
import { Subtitle } from '../Subtitle';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  h3 {
    margin: 0;
  }
`;

export const StyledSubtitle = styled(Subtitle)`
  padding: 6rem 1.75rem;
  text-align: center;
`;